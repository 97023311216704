<template>
  <div class="basicSettings">
    <!-- 操作设置 -->
    <div v-if="operateShow">
      <a-button type="primary" @click="setSave">保存</a-button>
      <a-button @click="setCancel" style="margin-left: 10px">重置</a-button>
    </div>
    <!-- 佣金比例 -->
    <div class="commission_rate basic_content">
      <div class="header_title">佣金比例设置</div>
      <div class="content table_list">
        <table border class="ontable">
          <tr>
            <th>商品类型</th>
            <th>佣金比例</th>
          </tr>
          <tr v-for="(item,index) in form.userPromotionList " :key="index">
            <td>
              <span v-if="item.commissionSharingLevel==1">一级分佣</span>
              <span v-if="item.commissionSharingLevel==2">二级分佣</span>
              <span v-if="item.commissionSharingLevel==3">三级分佣</span>
            </td>
            <td>
              <div class="rate_list">
                <a-input
                  v-model="item.commissionSharingRatio"
                  placeholder="请输入内容"
                  class="input"
                  style="width: 150px"
                  type="number"
                ></a-input>
                <span class="icon">%</span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- 提现规则 -->
    <div class="commission_rate basic_content">
      <div class="header_title">提现规则</div>
      <div class="content">
        <div class="header_title role_list">
          <div>提现手续费，从提现额中扣</div>
          <a-input
            v-model="form.withdrawalHandlingFee"
            placeholder="请输入"
            class="input role_input"
            style="width: 150px"
            type="number"
          ></a-input>
          <div>%（不填则不收取提现手续费）</div>
        </div>
      </div>
    </div>

    <div class="commission_rate basic_content">
      <div class="header_title">更多设置</div>
      <div class="content">
        <div class="header_title noticeContent">
          <div>分销协议模板：</div>
          <editor v-model="form.protocolTemplate" />
          <!-- <a-textarea v-model="form.protocolTemplate" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserPromotionResult, addUserPromotion } from '@/api/promotion/index'
import Editor from '@/components/Editor/Editor'
export default {
  components: {
    Editor
  },
  data() {
    return {
      operateShow:false,
      form: {
        protocolTemplate: null,
        withdrawalHandlingFee: null,
        userPromotionList: []
      },
      dataList: [
            {
               commissionSharingLevel: 1,
               commissionSharingRatio: 0
            }, {
               commissionSharingLevel: 2,
               commissionSharingRatio: 0
            }, {
              commissionSharingLevel: 3,
              commissionSharingRatio: 0
            }
          ]
    }
  },
  created() {
    this.getUserPromotionResult()
  },
  methods: {
    // 保存
    setSave() {
      addUserPromotion(this.form).then((response) => {
        console.log(response)
      })
    },
    // 取消
    setCancel() {
      this.form.userPromotionList = this.dataList
      this.form.protocolTemplate = null
      this.form.withdrawalHandlingFee = null
    },
    // "data": {
    //     "protocolTemplate": "",
    //     "withdrawalHandlingFee": null,
    //     "userPromotionList": []
    // },
    getUserPromotionResult() {
      getUserPromotionResult().then((response) => {
        if (response.success) {
          if (response.data.userPromotionList.length === 0) {
              this.form.userPromotionList = this.dataList
              this.operateShow = true
          } else {
             this.operateShow = false
            this.form.userPromotionList = response.data.userPromotionList
            this.form.protocolTemplate = response.data.protocolTemplate
            this.form.withdrawalHandlingFee = response.data.withdrawalHandlingFee
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.basicSettings {
  padding: 20px;
  background: #fff;
}
.operate {
  display: flex;
  align-items: center;
}

.basic_content {
  margin-top: 20px;
}
.header_title {
  font-size: 16px;
  font-weight: 600;
}
.content {
  margin-top: 15px;
}
.table_list {
  width: 40%;
}
.ontable {
  width: 100%;
  border-top: none;

  border-collapse: collapse;
}
table {
  border-color: #cecece !important;
}

th {
  background: #e8e8e8;
  padding: 10px 0px;
  font-weight: 500;
}
th,
td {
  border-color: #cecece !important;
  border: 1px solid #000;
  width: 50%;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.rate_list {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}

.icon {
  margin-left: 10px;
}

.role_list {
  margin-bottom: 15px;
  display: flex;
}

.role_input {
  margin: 0px 20px;
}

.noticeContent {
  margin-top: 10px;
  display: flex;
}
</style>