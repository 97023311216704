import request from '@/utils/request'
// 查询退货订单列表
export function getUserPromotionResult(query) {
  return request({
    url: '/promotion/getUserPromotionResult',
    method: 'get'
  })
}
export function aa(query) {
  return request({
    url: '/promotion/getUserPromotionResult',
    method: 'get',
    params: query
  })
}
// 新增退货订单
export function addUserPromotion(data) {
  return request({
    url: '/promotion/addUserPromotion',
    method: 'post',
    data: data
  })
}
